import localeLogin from '@/views/login/locale/en-US';
import localeHome from '@/views/down/locale/en-US';
import localeSettings from './en-US/settings';
import localeAll from './en-US/all';
import localePri from './en-US/pri';

export default {
  'menu.required': 'Please fill in all required fields.',
  'menu.locale': 'English',
  'menu.dark': 'Dark Mode',
  'menu.balance': 'Balance',
  'menu.recharge': 'Recharge',
  'menu.buycoins': 'Buy coins',
  'menu.financial': 'Financial',
  'menu.transferaccounts': 'Transfer',
  'menu.withdrawal': 'Withdrawal',
  'menu.c2corder': 'C2c Order',
  'menu.loginOut': 'Log out of login',
  'menu.payhis': 'Pay Historical Transactions',
  'menu.wallethistory': 'Wallet History',
  'menu.title': `BasicEx world's leading cryptocurrency payment service provider`,
  'navbar.action.locale': 'Switch to English',
  'public.next': 'Next',
  'public.submit': 'submit to',
  ...localeSettings,
  ...localeLogin,
  ...localeHome,
  ...localeAll,
  ...localePri,
};
